<template>
  <div id="category">
    <br />
    <div class="flex">
       <div class="flex-item flex-2 "></div>
    <select class="select-css flex-item flex-1 flex-center language-slect" v-model="lang_select" @change="updateLang(lang_select)" >
      <!-- <option value="">TH</option> -->
      <option value="_th">TH</option>
      <!-- <option value="_ja">JA</option> -->
      <!-- <option value="_cn">CN</option> -->
    </select>
    <div class="flex-item flex-1 "></div>
    </div>
    <br />
    <!-- Logo -->
    <section id="restaurant-logo">
      <the-logo />
    </section>
    <!-- End Logo -->
    <!-- Category -->
    <section id="categories-container">
      <category-item
        v-for="category in activeCategories"
        class="category"
        :key="category.id"
        :category="category"
        :lang_select="lang_select"
        @click="onCLickCategory(category.id)"
      >
      </category-item>
    </section>

    <the-footer />
  </div>
</template>

<script>
import CategoryItem from './CategoryItem'
import TheFooter from '@/components/TheFooter'
import TheLogo from '@/components/TheLogo'
export default {
  components: {
    CategoryItem,
    TheFooter,
    TheLogo
  },
  data() {
    return {
      lang_select: '',
      languagess: ['TH', 'EN', 'JP', 'CH'],
      test: [
        {
          name: 'en',
          name_th: 'th',
          name_ja: 'ja',
          name_cn: 'cn'
        }
      ]
    }
  },
  computed: {
    activeCategories() {
      if (this.$store.state.api.menuDataapi) {
        return this.$store.state.api.menuDataapi
      } else {
        return []
      }
    }
  },
  methods: {
    gotoCategoryDetail(cateId) {
      this.$router.push({ path: '/category/' + cateId })
    },
    onCLickCategory(cateId) {
      this.gotoCategoryDetail(cateId)
    },
    updateLang(lang) {
      this.$store.dispatch('updateLang', lang)
    }
  },
  mounted() {
    this.lang_select = this.$store.state.language
    // setInterval(function() {
    //   this.$store.dispatch('updateLang', this.lang_select)
    // }, 500)
  }
}
</script>

<style lang="scss" scoped>
#category {
  color: white;
  width: 100%;
}

/* Logo. */
#restaurant-logo {
  background: var(--foreground);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-content: center;
  color: black;
  margin-top: 32px;
}

.restaurant-logo__title {
  font-weight: 700;
  font-size: 1.4em;
  align-self: center;
  padding: 10px;
}
.restaurant-logo__description {
  line-height: 0em;
  align-self: center;
}

#restaurant-logo > img {
  max-height: 150px;
  max-width: 100%;
  align-self: center;
}
/* EndLogo. */

/* categories container. */
#categories-container {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}
/* end categories container. */

#categories-footer {
  padding: 3em;
  min-height: 200px;
}

#categories-footer {
  font-size: 1.2em;
  text-align: center;
  color: var(--dark);
}

#categories-footer > .font-1 {
  font-size: 1.2em;
}

#categories-footer > .font-2 {
  font-size: 0.9em;
}

#categories-footer > .font-3 {
  font-size: 0.6em;
}

#categories-footer > .font-4 {
  font-size: 0.5em;
}

@media (max-width: 768px) {
  #restaurant-logo > img {
    height: auto;
  }
}

.language-slect {
  padding-right: 0.5rem !important;
}

.select-css{
  display: block;
  font-size: 15px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: .2em 1.5em .2em .8em;
  max-width: 100%; /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  border-radius: .5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
  linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right .7em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: .65em auto, 100%;
}
/* Hide arrow icon in IE browsers */
.select-css::-ms-expand {
  display: none;
}
/* Hover style */
.select-css:hover {
  border-color: #888;
}
/* Focus style */
.select-css:focus {
  border-color: #aaa;
  /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
  box-shadow: 0 0 1px 1px rgba(59, 153, 252, .7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}

/* Set options to normal weight */
.select-css option {
  font-weight:normal;
}

/* Support for rtl text, explicit support for Arabic and Hebrew */
*[dir="rtl"] .select-css, :root:lang(ar) .select-css, :root:lang(iw) .select-css {
  background-position: left .7em top 50%, 0 0;
  padding: .6em .8em .5em 1.4em;
}

/* Disabled styles */
.select-css:disabled, .select-css[aria-disabled=true] {
  color: graytext;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
}

.select-css:disabled:hover, .select-css[aria-disabled=true] {
  border-color: #aaa;
}

.flex-2{
  flex: 9;
}
</style>
