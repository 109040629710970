import Vue from 'vue'
import Vuex from 'vuex'
import menu from './menu.module'
import api from './api.module'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: '_th'
  },
  mutations: {
    UPDATE_LANG(state, lang) {
      state.language = lang
    }
  },
  actions: {
    updateLang(context, lang) {
      context.commit('UPDATE_LANG', lang)
    }
  },
  modules: {
    menu,
    api
  }
}

)
